@font-face {
  font-family: "iconfont"; /* Project id 3869995 */
  src:
       url('../font/iconfont.ttf?t=1674805582038') format('truetype');
}

/*@font-face {*/
/*    font-family: "楷体";*/
/*    src: url("../font/simkai.ttf");*/
/*}*/

.iconfont {
    font-family: "iconfont" !important;
}
.icon-cuo:before {
  content: "\e63d";
}

.icon-shuruzhengque:before {
  content: "\e664";
}

.icon-zhengque-correct:before {
  content: "\e652";
}
.icon-cuowu:before {
  content: "\e6a8";
}

.icon-duigou:before {
  content: "\e640";
}

.icon-tuijian:before {
  content: "\e608";
}
.icon-yonghu1:before {
  content: "\e634";
}
.icon-yonghu:before {
  content: "\e620";
}

.icon-return:before {
  content: "\e6ba";
}

.icon-fanhui:before {
  content: "\e624";
}

.icon-fanhui1:before {
  content: "\e72e";
}

.icon-fanhui2:before {
  content: "\e626";
}

.icon-sousuo3:before {
  content: "\e622";
}

.icon-sousuo1:before {
  content: "\e651";
}

.icon-sousuo2:before {
  content: "\e603";
}
.icon-sousuo:before {
  content: "\e618";
}

.icon-youxiang:before {
  content: "\e908";
}

.icon-24gl-telephone2:before {
  content: "\e953";
}

.icon-home:before {
  content: "\e6b8";
}

.icon-hycaidan:before {
  content: "\e62e";
}

.icon-icon7:before {
  content: "\e663";
}

.icon-xuexi:before {
  content: "\e63b";
}

.icon-ceshi:before {
  content: "\e629";
}

.icon-xuexi1:before {
  content: "\e601";
}

html{
    font-size: 13.333333vw;
}

body{
    font-size: .3rem;
    -webkit-text-size-adjust: none;
}
body,
h1,
h2,
h3,
h4,
p,
d1,
dd{
    margin: 0;
}
ul{
    margin: 0;
    padding: 0;
    list-style: none;
}
img{
    vertical-align: top;
}

a{
    text-decoration: none;
}
input,
textarea,
a{
    outline: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0) ;
    -webkit-appearance: none;
}
textarea{
    overflow: auto;
    resize: none;
}
a:active,
.a-active{
    opacity: .8;
}

　a:link {
color:#000;
text-decoration:none;
}
a:visited {
color:#000;
text-decoration:none;
}
a:hover {
color:#000000;
text-decoration:none;
}
a:active {
color:#FFFFFF;
text-decoration:none;
}

.clear:after {
    content: "";
    display: block;
    clear: both;
}

#header{
    position: fixed;
    height: .9rem;
    left: 0;
    width: 100vw;
    background: bisque;
    z-index: 100;
}
.header-btn-right,
.header-btn-left{
    position: absolute;
    top: 0;
    width: .9rem;
    height: .9rem;
    text-align: center;
    line-height: .9rem;
    font-size: .5rem;
}
.header-btn-left{
    left: 0;
}

.header-btn-right{
    right: 0;
}
#logo{
    margin: .03rem auto 0;
    display: block;
    width: 2.84rem ;
  }

.miaov-btn{
    background: linear-gradient(-72deg, #d46b16, #f2bf6a);
    box-shadow: 1px 1px 5px rgba(255, 162, 86, 0.5);
    text-align: center;
    color: #fff;
}

.miaov-btn-md{
    width: 3rem;
    height: .7rem;
    font: .34rem/.7rem "\5B8B\4F53";
    color: #fff;
    text-align: center;
    letter-spacing: 3px;
    border-radius: .35rem;
    border: 0;
}

#homebutton{
    display: block;
    width: 100vw ;
    text-align: center;
    box-sizing: border-box;
    padding: .1rem 1.2rem .5rem 1.2rem;
  }

.threebutton{
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 3.2rem;
}
.hometitle{
    position: absolute;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*box-sizing: border-box;*/
    padding-top: .4rem;
}

.footer-nav {
    padding: .15rem .3rem .35rem;
    background: #323436;
}
.miaov-contact a {
    float: left;
    position: relative;
    font: .24rem/.6rem "\5BBB\4F53";
    color: #fff;
    padding: 0 .2rem 0 .95rem;
}

.miaov-contact a:before {
    position: absolute;
    left: .37rem;
    top: 0;
    font-size: .4rem;
}

.footer-nav nav a {
    float: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 25%;
    margin-top: .2rem;
    font: .24rem/.26rem "\5BBB\4F53";
    color: #adaeae;
    padding-left: .37rem;
    border-right: 1px solid #adaeae;
}
.footer-nav nav a:nth-of-type(4n){
    border: none;
}
.footer-copy{
    font: .24rem/.74rem "\5FAE\8F6F\96C5\9ED1";
    text-align: center;
    color: #868b8d;
    background: #2d2f30;
}
.page{
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height: 100vh;
    padding-top: .9rem;
    z-index: 10;
    background: #f2f9fc;
}


.page-con{
    flex: 1;
}
#menu{
    box-sizing: border-box;
    position: fixed;
    left: 0;
    top: 0;
    padding: 1.6rem .5rem;
    width: 4.5rem;
    height: 100vh;
    background: url("../img/menu.png") no-repeat;
    background-size: cover;
    z-index: 1;
}
#menu a {
    display: block;
    font: .35rem/.9rem "宋体";
    border-bottom: 1px solid orangered;
    color: sienna;
}
#menu a:before{
    color: fuchsia;
    margin: 0 .2rem;
    font-size: .4rem;
    vertical-align: middle;
}
#menu a:first-child{
    border-top: 1px solid orangered;
}
.tab{
    transform-style: preserve-3d;
    position: relative;
    overflow: hidden;
}
.MP4tab{
    position: relative;
    overflow: hidden;
    z-index: 0;
}
.picList{
    backface-visibility: hidden;
    width: 400vw;
    overflow: hidden;
}
.banner .picList{
    width: 400vw;
    overflow: hidden;
}

.picList li {
    float: left;
    width: 100vw;
}
.banner .picList img{
    width: 100vw;
}
.banner .picNav{
    position: absolute;
    left: 0;
    width: 100vw;
    bottom: .2rem;
    height: .1rem;
    text-align: center;
}
.banner .picNav span{
    display: inline-block;
    vertical-align: top;
    margin: 0 .1rem;
    width: .3rem;
    height: .1rem;
    background: rgba(255,255,255,.5);
}
.banner .picNav .active{
    background: #fff;
}
.banner img {
    height: 4.6rem;

}

.home-page .page-con {
    background: url("../img/home/home.png") no-repeat;
    /*background-size: 100vw auto;*/
     background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

.afterclass-page .page-con {
    background: url("../img/card/page.png") no-repeat;
    /*background-size: 100vw 79vh;*/
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

.inter-title{
    font: .5rem/1rem "楷体";
    text-align: center;
}
.inter-con{
    padding: 0 .21rem 1px;
    overflow: hidden;
    z-index: 9999;
}
.inter-con li{
    float: left;
    width: 7rem;
}
.inter-con a{
    position: relative;
    display: block;
    background: #fff;
    transition: .1s cubic-bezier(.19, .89, .61 ,1.22);
}
.inter-con a:before{
    position: absolute;
    color: rgba(0,0,0,.2);
}
.inter-con a:active,
.inter-con .a-active{
    transform: scale(1.2);
}
.inter-box{
    margin: .2rem 0 .2rem 0;
    padding-top: .85rem;
    position: relative;
    height: 2.63rem;
    border: 1px solid #dce7eb;
    text-align: center;
    color: #666;
}


.inter-sun .inter-box{
    position: relative;
    background: url("../img/interaction/sun.png") no-repeat;
    background-size: cover;
    color: black;
}


.inter-pubu .inter-box{
    position: relative;
    background: url("../img/interaction/pubu.png") no-repeat;
    background-size: cover;
    color: black;
}
.inter-zhu .inter-box{
    position: relative;
    background: url("../img/interaction/zhu.png") no-repeat;
    background-size: cover;
    color: black;
}
.inter-moon .inter-box{
    position: relative;
    background: url("../img/interaction/moon.png") no-repeat;
    background-size: cover;
    color: black;
}
.inter-huan .inter-box{
    position: relative;
    background: url("../img/interaction/huan.png") no-repeat;
    background-size: cover;
    color: black;
}
.inter-qiao .inter-box{
    position: relative;
    background: url("../img/interaction/qiao.png") no-repeat;
    background-size: cover;
    color: black;
}
.inter-montain .inter-box{
    position: relative;
    background: url("../img/interaction/montain.png") no-repeat;
    background-size: cover;
    color: black;
}
.inter-montain2 .inter-box{
    position: relative;
    background: url("../img/interaction/montain2.png") no-repeat;
    background-size: cover;
    color: black;
}
.problem{
    margin: .34rem auto;
    width: 6.7rem;
    position: relative;
    height: 12rem;
    transform-style: preserve-3d;
    /*display: block;*/
    /*box-sizing: border-box;*/
    /*margin: .5rem auto;*/
    /*padding: .25rem;*/
    /*width: 80vw;*/
    /*height: 2.5rem;*/
    /*border: 1px solid #ccc;*/
    /*font: .3rem/.5rem "宋体";*/
}
.problem-inner{
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    padding: .25rem;
    width: 100%;

    background: rgba(255,255,255,.3);
    box-shadow: 2px 3px 10px rgba(0,0,0,.2);
}
.problem-btn{
    display: block;
    margin: .2rem auto;
    border: 0;
    transition: .1s cubic-bezier(.19, .89, .61 ,1.22);
}
.problem-btn:active{
    transform: scale(1.2);
}
.popup{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background-color: rgba(0,0,0,.5);
}
.popup-win{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 64%;
    margin: -3.2rem 0 0 -3.5rem;
    width: 6.8rem;
    height: 7.9rem;
    background: url("../img/interaction/popup.png") no-repeat;
    background-size: cover;
}
.popup-con{
    margin-top: .1rem;
    padding: 0 1.2rem;
    height: 5.5rem;
    font: .4rem/.42rem "楷体";
    line-height: .6rem;
    color: black;
    word-break: break-all;
    overflow-y: auto;
}
.popup-title{
    font: .5rem/1.08rem "楷体";
    text-align: center;
    color: black;
    position: relative;
}
.popup-photo{
    box-sizing: border-box;
    position: absolute;
    left: 2.5rem;
    top: -.44rem;
    width: 1.8rem;
    height: 1.8rem;
    background: blanchedalmond no-repeat;
    background-size: contain !important;
    border-radius: .9rem;
    border: 4px solid #fff;
}
.close{
    position: absolute;
    right: .34rem;
    top: .1rem;
    width: .6rem;
    height: .6rem;
    background: url("../img/close_fill.png");
    background-size: cover;
}
#popup-win{
    position: absolute;
}
#popup{
    position: fixed;
    right: 0;
    top: 0;
    width: 50vw;
    height: 100vh;
    background: rgba(0,0,0,.5);
}
.search-container{
    /*box-sizing: border-box;*/
    /*position: absolute;*/
    /*margin: .34rem auto;*/
    /*padding: .25rem;*/

    display: flex;
    justifyContent: 'center';
    width: 90vw;
    line-height: 2;
    background-color: blanchedalmond;
    border-radius: 60px;
    overflow: hidden;
    padding: 4px;
    height: .8rem;
    text-align: center;
}

.search-container .i{
    /*background-color: #ccc !important;*/
    text-align: center;
    display: flex;
    position: absolute;
    margin: .05rem 0 0 .05rem;
    width: .6rem;
    height: .6rem;
    background: url("../img/206搜索.png") no-repeat;
    background-size: cover;
}

.search-container .input{
    /*font-size: 18px;*/
    border: none;
    display: flex;
    margin: -.05rem 0 0 .9rem;
    width: 5.5rem;
    /*position: relative;*/
}

.search-kongbai{
    margin: 0;
    padding-top: 0.5rem;
}
.search-form{
    display: block;
    width: auto;
    margin: 0 .3rem 0 .3rem;
    /*color: #718096;*/
    position: relative;
    overflow: hidden;
    /*border-radius: 5px;*/
}
.search{
    padding-right: 1rem;
    padding-left: 1rem;
    height: .6rem;
    border-width: 1px;
    border-radius: .5rem;
    background-color: #fff;
    width: 4rem;
}


.btn-search{
    top: 0;
    right: 0;
    position: absolute;
    display: block;
    vertical-align: middle;
    margin-right: .7rem;
    margin-top: .38rem;
    background-color: transparent;
    width: 1rem;
    height: 1rem;
    border: 0;
}
.search_tuijian_content{
    padding: 0.55rem 0.55rem 2rem 0.55rem;
}
.search_tuijian_content .search_tuijian_title{
    font-weight: 400;
    color: #010101;
    opacity: 0.8;
    font-size: 0.32rem;
    margin-bottom: 0.2rem;
}
.search_tuijian_content .search_tuijian_name{
    width: 1.38rem;
    height: .6rem;
    line-height: .6rem;
    text-align: center;
    background: rgba(0,0,0,.1);
    opacity: .8;
    border-radius: 0.16rem;
    color: #000;
}
.search_tuijian_cardContent .search_tuijian_name{
    float: left;
    margin-left: 0.2rem;
    margin-bottom: 0.2rem;
}


.question{
    position: relative;
    display: block;
    width: 100%;
    padding: 5px;
    font-size: 16px;
    line-height: 25px;
    margin: .4rem .5rem .5rem 0;
}

.Submitbtn{
    margin-top: 20px;
}
.desc{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}
.title1{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: .12rem;
}
.title{
    font-size: 16px;
    font-weight: bold;
}

.totalScore{
    position: absolute;
    left: 5.4rem;
    top: .2rem;
    font-size: 40px;
    font-weight: bold;
    color: red;
    font-family: 'Times New Roman', Times, serif;
}

.wrapper {
    position: relative;
}
.duiicon{
    position: absolute;
    top:.3rem;
    z-index: 9999;
    color: green;
    font-weight: bold;
    font-size: .6rem;
}
.cuoicon{
    position: absolute;
    top:.3rem;
    z-index: 9999;
    color: red;
    font-weight: bold;
    font-size: .5rem;
}
.duiicon2{
    position: absolute;
    top:.6rem;
    left: .18rem;
    z-index: 9999;
    color: green;
    font-weight: bold;
    font-size: .6rem;
}
.cuoicon2{
    position: absolute;
    top:.6rem;
    left: .18rem;
    z-index: 9999;
    color: red;
    font-weight: bold;
    font-size: .6rem;
}

/*.duicuo{*/
/*    position: absolute;*/
/*    top:.6rem;*/
/*    left: .25rem;*/
/*    z-index: 9999;*/
/*    color: red;*/
/*    font-weight: bold;*/
/*}*/

.question-input{
    line-height: 1;
    border-radius: 5px;
    overflow: hidden;
    padding: 2px;
    font-size: 15px !important;
    font-weight: normal;
    width: 2.3rem;
    text-align: center;
}

/*搜索卡片结果*/
.list{
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}
.list h3{
    padding-left: 0.2rem;
    padding-bottom: 0.2rem;
}
.cardbox{
    height: 3.8rem;
    background-color: pink;
    position: relative;
    margin-bottom: .4rem;
}
.cardbox img{
    width:100%;
    height:3.8rem;
}

.cardbox .cardwrapper{
    padding: 0 .2rem;
    line-height: .6rem;
    height: .6rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,.5);
}
.cardwrapper .good{
    float: left;
    font-size: .32rem;
}
.cardwrapper span{
    float: right;
    font-size: .5rem;
    transition: .1s cubic-bezier(.19, .89, .61 ,1.22);
}
.cardwrapper span:active,
.cardwrapper .span-active{
    transform: scale(1.25);
}
.cardwrapper .isGood{
    color: #f60;
}

.cardImg{
    /*margin: .2rem 0 .2rem 0;*/
    padding-top: .85rem;
    position: relative;
    /*height: 2.63rem;*/
    /*border: 1px solid #dce7eb;*/
    /*text-align: center;*/

    /*background: no-repeat;*/
    background-size: cover;
}

/*.classtestButton{*/
/*    !*position: relative;*!*/
/*    display: block;*/
/*    box-sizing: border-box;*/
/*    width: 40%;*/
/*    padding: 5px;*/
/*    font-size: 16px;*/
/*    line-height: 25px;*/
/*    !*margin: .4rem .5rem .5rem 0;*!*/
/*}*/

.classtest{
    margin: .34rem auto;
    width: 6.7rem;
    position: relative;
    height: 3.4rem;
    transform-style: preserve-3d;
    /*display: block;*/
    /*box-sizing: border-box;*/
    /*margin: .5rem auto;*/
    /*padding: .25rem;*/
    /*width: 80vw;*/
    /*height: 2.5rem;*/
    /*border: 1px solid #ccc;*/
    /*font: .3rem/.5rem "宋体";*/
}

#classtestbutton{
    display: flow;
    width: 50% ;
    height: 1rem;
    box-sizing: border-box;
    padding: .1rem .1rem .1rem .1rem;
    margin: .1rem 0rem 0rem 0;
  }

.partonefour{
    margin: .34rem auto;
    width: 6.7rem;
    position: relative;
    height: 7rem;
    transform-style: preserve-3d;
}

.parttwothree{
    margin: .34rem auto;
    width: 6.7rem;
    position: relative;
    height: 10.8rem;
    transform-style: preserve-3d;
}


.resource1 .inter-box{
    position: relative;
    background: url("../img/interaction/sun.png") no-repeat;
    background-size: cover;
    color: black;
}
.wenjuan{
    margin: .34rem auto;
    width: 6.7rem;
    position: relative;
    height: 1.4rem;
    transform-style: preserve-3d;
}
.resource_tui{
    margin: .34rem auto;
    width: 6.7rem;
    position: relative;
    height: 5.1rem;
    transform-style: preserve-3d;
}
